<template>
  <vx-card>
    <div style="position:relative;">
      <div style="position:absolute;top:0;right:0;padding-right:0;z-index:1000;">
        <ul style="display:block;text-align:right;">
          <li style="display:inline-block;margin-right:5px;">
            <vs-button style="padding:5px 15px;" @click="onSave()">Save
            </vs-button>

          </li>
          <li style="display:inline-block">
            <vs-button style="padding:4px 15px;" @click="() => { }" type="border">Cancel
            </vs-button>
          </li>
        </ul>
      </div>
      <vs-tabs class="supplier-detail">
        <vs-tab label="Supplier Details">
          <div>
            <SupplierDetails @createOrUpdateSupplier="createOrUpdateSupplier" :supplierId="supplierId" :onSave="save" />
          </div>
        </vs-tab>
        <vs-tab label="Products">
          <div>
            <ProductList :supplierId="supplierId" />
          </div>
        </vs-tab>
        <vs-tab label="Orders">
          <div>
            <OrderList :supplierId="supplierId" @viewOrderDetails="viewOrderDetails" />
          </div>
        </vs-tab>
        <vs-tab label="Clinics">
          <div>
            <ClinicListModule :canViewDetail="true" viewRoute="SuperAdminClinicDetail" :supplierId="supplierId" />
          </div>
        </vs-tab>
      </vs-tabs>

    </div>
  </vx-card>
</template>

<script>
import SupplierDetails from "../../../components/WebStore/suppliers/SupplierDetailsV2";
import ProductList from "../../../components/WebStore/suppliers/ProductList";
import OrderList from "../../../components/WebStore/suppliers/OrderList";
import ClinicListModule from "../../../components/WebStore/suppliers/ClinicListModule";
import { mapActions } from "vuex";

export default {
  name: 'View-Supplier',
  components: {
    ClinicListModule,
    SupplierDetails,
    ProductList,
    OrderList
  },
  data: () => ({
    supplierId: '',
    save: false
  }),
  methods: {
    ...mapActions("supplier", ["updateSupplierDetails"]),
    createOrUpdateSupplier(data) {
      this.$vs.loading();
      let value = {
        id: data._id,
        info: data
      };
      this.updateSupplierDetails(value).then(res => {
        this.$vs.notify({
          title: "Supplier Edit",
          text: "Supplier edited successfully",
          color: "success"
        });
        this.$vs.loading.close();
      });
    },
    viewOrderDetails(data) {
      this.$router.push({
        name: "superAdminStoreViewSuppliersOrderDetail",
        params: { orderId: data.orderId, supplierId: data.supplierId }
      });
    },
    onSave() {
      this.save = true;
      setTimeout(() => {
        this.save = false;
      }, 1000)
    }
  },
  async created() {
    this.supplierId = this.$route.params.supplierId;
  }
};
</script>
